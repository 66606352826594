
<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="true">
    <div>
      <label>创建时间：<span>{{ order_info.create_time | dayjs }}</span></label>
      <label style="float:right">支付时间：<span>{{ order_info.pay_time ? (order_info.pay_time | dayjs) : '未支付'
      }}</span></label>
    </div>
    <a-divider style="margin: 16px 0" orientation="left">教员信息</a-divider>
    <detail-list size="small" :col="3" class="detail-layout">
      <detail-list-item term="姓名">{{ teacher_info.auth_real_name + '—' + teacher_info.nick_name }}
        <a-icon v-if="teacher_info.auth_status" style="color: green;" type="check" />
      </detail-list-item>
      <detail-list-item term="年龄">{{ teacher_info.age }}</detail-list-item>
      <detail-list-item term="性别">{{ teacher_info.sex }}</detail-list-item>
      <detail-list-item term="目前身份">{{ teacher_info.current_status }}</detail-list-item>
      <detail-list-item term="地区">{{ teacher_info.province + ' ' + teacher_info.city }}</detail-list-item>
      <detail-list-item term="联系地址">{{ teacher_info.area + teacher_info.address }}</detail-list-item>
      <detail-list-item term="联系电话">{{ teacher_info.phone }}</detail-list-item>
    </detail-list>
    <a-divider style="margin: 16px 0" orientation="left">服务包信息</a-divider>
    <detail-list size="small" :col="3" class="detail-layout">
      <detail-list-item term="包名">{{ pack_info.title }}</detail-list-item>
      <detail-list-item term="描述">{{ pack_info.desc }}</detail-list-item>
      <detail-list-item term="当前状态">{{ pack_info.status === 1 ? '上架' : '下架' }}</detail-list-item>
      <detail-list-item term="支持周期">{{ pack_info.min_cycle + '~' + pack_info.max_cycle }}</detail-list-item>
      <detail-list-item term="支持星期">{{ pack_info.week_day_str }}</detail-list-item>
      <detail-list-item term="支持时间">{{ pack_info.min_day + '~' + pack_info.max_day }}</detail-list-item>
    </detail-list>
    <a-divider style="margin: 16px 0" orientation="left">家长信息</a-divider>
    <detail-list size="small" :col="3" class="detail-layout">
      <detail-list-item term="姓名">{{ user_info.auth_real_name + '—' + user_info.nick_name }}</detail-list-item>
      <detail-list-item term="性别">{{ user_info.sex }}</detail-list-item>
      <detail-list-item term="支付订单数">{{ user_info.order_count }}</detail-list-item>
      <detail-list-item term="地区">{{ user_info.province + ' ' + user_info.city }}</detail-list-item>
      <detail-list-item term="联系地址">{{ user_info.address }}</detail-list-item>
      <detail-list-item term="联系电话">{{ user_info.phone }}</detail-list-item>
    </detail-list>
    <a-divider style="margin: 16px 0" orientation="left">学员信息</a-divider>
    <detail-list size="small" :col="3" class="detail-layout">
      <detail-list-item term="姓名">{{ student_info.nick_name }}</detail-list-item>
      <detail-list-item term="性别">{{ student_info.sex }}</detail-list-item>
      <detail-list-item term="阶段">{{ student_info.stage }}</detail-list-item>
      <detail-list-item term="欠缺科目">{{ student_info.coach_subject }}</detail-list-item>
      <detail-list-item term="描述">{{ student_info.desc }}</detail-list-item>
      <detail-list-item term="对教员的要求">{{ student_info.require_desc }}</detail-list-item>
    </detail-list>
    <a-divider style="margin: 16px 0" orientation="left">订单详情</a-divider>
    <detail-list size="small" :col="3" class="detail-layout">
      <detail-list-item term="订单号">{{ order_info.ordernum + '（ID - ' + order_info.id + '）' }}</detail-list-item>
      <detail-list-item term="周期">{{ order_info.cycle + '周' }}</detail-list-item>
      <detail-list-item term="支付方式">{{ order_info.payment_method }}</detail-list-item>
      <detail-list-item term="授课时间">{{ order_info.data_info_str }}</detail-list-item>
      <detail-list-item term="优惠码">{{ order_info.promocode ? order_info.promocode : '无' }}</detail-list-item>
      <detail-list-item term="订单状态">
        <a-badge :status="orderStatus | statusTypeFilter" :text="orderStatus | statusFilter" />
      </detail-list-item>
      <detail-list-item term="备注">{{ order_info.desc }}</detail-list-item>
      <detail-list-item term="IP地址">{{ order_info.ipaddress }}</detail-list-item>
      <detail-list-item term="支付状态">{{ order_info.pay_status }}</detail-list-item>
      <detail-list-item term="金额">
        <a-input style="width:30%;margin-right: 5px" v-model="amount" />
        <a-button :loading="loading.changeAmount" @click="amountChangeHandle" type="link">更改</a-button>
      </detail-list-item>
    </detail-list>
    <a-divider style="margin: 16px 0" orientation="left">时间安排</a-divider>
    <a-table :columns="columns" :dataSource="orderItem" rowKey="id">
    </a-table>
    <a-divider style="margin: 16px 0" orientation="left">操作</a-divider>
    <div v-if="orderStatus != 'Cancelled' ? true : false" style="text-align: center">
      <a-popconfirm title="确定取消该订单?" @confirm="() => orderActiveHandle()">
        <a-button class="fun-btn" :disabled="orderStatus != 'Active' ? false : true" type="primary"
          :loading="loading.orderActive">激活订单</a-button>
      </a-popconfirm>
      <a-popconfirm title="确定取消该订单?" @confirm="() => orderCancelHandle()">
        <a-button class="fun-btn" :loading="loading.orderCancel">取消订单</a-button>
      </a-popconfirm>
      <a-button class="fun-btn" :disabled="payStatus == 'Paid' ? false : true">取消并退款</a-button>
      <a-popconfirm title="确定标记该订单为已支付?" @confirm="() => orderToPaidHandle()">
        <a-button class="fun-btn" :disabled="payStatus == 'Paid' ? true : false" :loading="loading.orderToPaid">修改为已支付
        </a-button>
      </a-popconfirm>
      <a-button class="fun-btn" :loading="loading.orderToFraud" @click="orderToFraudHandle">设置为欺诈</a-button>
    </div>
  </a-card>
</template>

<script>
import DetailList from '@/components/tools/DetailList'
import { orderInfo, orderToChangeAmount, orderCancel, orderToChangePaid, orderToChangeActive, OrderToChangeFraud } from '@/api/order'

const DetailListItem = DetailList.Item

const statusMap = {
  'Pending': {
    status: 'warning',
    text: '等待中'
  },
  'Active': {
    status: 'success',
    text: '已激活'
  },
  'Cancelled': {
    status: 'default',
    text: '已取消'
  },
  'Fraud': {
    status: 'error',
    text: '有欺诈'
  }
}

export default {
  name: 'TeacherResume',
  components: {
    DetailList,
    DetailListItem
  },
  data() {
    return {
      loading: {
        changeAmount: false,
        orderActive: false,
        orderCancel: false,
        orderToPaid: false,
        orderToFraud: false
      },
      order_id: '',
      queryData: {},
      order_info: [],
      teacher_info: [],
      user_info: [],
      student_info: [],
      pack_info: [],
      orderItem: [],
      amount: 0,
      columns: [
        {
          title: '时间',
          dataIndex: 'date'
        },
        {
          title: '时长',
          dataIndex: 'hour'
        },
        {
          title: '描述',
          dataIndex: 'desc'
        },
        {
          title: '地址',
          dataIndex: 'address'
        }
      ],
      orderStatus: 'Pending',
      payStatus: 'Unpaid'
    }
  },
  created() {
    const id = this.$route.query.id
    if (id === undefined) {
      this.$router.back(-1)
    }
    this.order_id = id
    this.loadInfo(id)
  },
  mounted() {

  },
  watch: {
    order_info(val) {
      this.orderStatus = val.status
      this.orderItem = val.order_item
      this.amount = val.amount
      this.payStatus = val.pay_status
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  methods: {
    loadInfo(id) {
      new Promise((resolve, reject) => {
        orderInfo({ id: id }).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        this.order_info = data.order_info
        this.teacher_info = data.teacher_info
        this.user_info = data.user_info
        this.student_info = data.student_info
        this.pack_info = data.pack_info
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      })
    },
    amountChangeHandle() {
      this.loading.changeAmount = true
      new Promise((resolve, reject) => {
        orderToChangeAmount({ id: this.order_id, amount: this.amount }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.$notification.success({
          message: res.status,
          description: res.message,
          duration: 4
        })
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.loading.changeAmount = false
      })
    },
    // 激活订单
    orderActiveHandle() {
      this.loading.orderActive = true
      new Promise((resolve, reject) => {
        orderToChangeActive({ id: this.order_id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.$notification.success({
          message: res.status,
          description: res.message,
          duration: 4
        })
        this.loadInfo(this.order_id)
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.loading.orderActive = false
      })
    },
    // 取消订单
    orderCancelHandle() {
      this.loading.orderCancel = true
      new Promise((resolve, reject) => {
        orderCancel({ id: this.order_id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.$notification.success({
          message: res.status,
          description: res.message,
          duration: 4
        })
        this.loadInfo(this.order_id)
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.loading.orderCancel = false
      })
    },
    // 修改为已支付
    orderToPaidHandle() {
      this.loading.orderToPaid = true
      new Promise((resolve, reject) => {
        orderToChangePaid({ id: this.order_id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.$notification.success({
          message: res.status,
          description: res.message,
          duration: 4
        })
        this.loadInfo(this.order_id)
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.loading.orderToPaid = false
      })
    },
    // 设置为欺诈
    orderToFraudHandle() {
      this.loading.orderToFraud = true
      new Promise((resolve, reject) => {
        OrderToChangeFraud({ id: this.order_id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.$notification.success({
          message: res.status,
          description: res.message,
          duration: 4
        })
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
        this.loadInfo(this.order_id)
      }).finally(() => {
        this.loading.orderToFraud = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail-layout {
  margin-left: 44px;
}

.text {
  color: rgba(0, 0, 0, .45);
}

.heading {
  color: rgba(0, 0, 0, .85);
  font-size: 20px;
}

.no-data {
  color: rgba(0, 0, 0, .25);
  text-align: center;
  line-height: 64px;
  font-size: 16px;

  i {
    font-size: 24px;
    margin-right: 16px;
    position: relative;
    top: 3px;
  }
}

.fun-btn {
  margin-right: 5px;
}

.mobile {
  .detail-layout {
    margin-left: unset;
  }

  .status-list {
    text-align: left;
  }
}
</style>
