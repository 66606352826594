import api from './index'
import { axios } from '@/utils/request'

// 获取订单列表
export function orderList(parameter) {
    return axios({
        url: api.OrderList,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取订单详情
export function orderInfo(parameter) {
    return axios({
        url: api.OrderInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取订单编辑页面附加数据
export function orderAdditional() {
    return axios({
        url: api.OrderAdditional,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 新建订单
export function orderCreate(parameter) {
    return axios({
        url: api.OrderCreate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 取消订单
export function orderCancel(parameter) {
    return axios({
        url: api.OrderCancel,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改订单价格
export function orderToChangeAmount(parameter) {
    return axios({
        url: api.OrderToChangeAmount,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 标记账单为已支付
export function orderToChangePaid(parameter) {
    return axios({
        url: api.OrderToChangePaid,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 标记账单为激活
export function orderToChangeActive(parameter) {
    return axios({
        url: api.OrderToChangeActive,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 标记账单为欺诈
export function OrderToChangeFraud(parameter) {
    return axios({
        url: api.OrderToChangeFraud,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
